<template>
	<Menu actual="/padres-de-familia" class="mt-6" />

	<div class="py-16 bg-white overflow-hidden">
		<div class="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
			<div class="text-base max-w-prose mx-auto lg:max-w-none">
				<h2 class="text-base text-morado font-semibold tracking-wide uppercase">Primeros responsables</h2>
				<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-amarillo-dark sm:text-4xl">Padres de familia</p>
			</div>
			<div class="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
				<p class="text-lg text-gray-500">Los padres como  primeros responsables de la formación de sus hijas e hijos,  deben atender su educación sexual desde  edades tempranas. </p>
			</div>
			<div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
				<div class="relative z-10">
					<div class="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
						<p>Sabemos que es un tema difícil para muchos, pues aún existen  prejuicios y desinformación sobre el tema, sin embargo, el entorno y fuerzas del mundo exterior hacen necesario no descuidarla y tomar acción.</p>
						<p>Los niños y niñas crecen confundidos, por un lado los medios los saturan de información sexual que en la mayoría de los casos no es propia de su edad,  y por otro las personas en quien ellos confían deciden guardar silencio,   obligándolos a  tomar como modelo o fuente de información lo que el medio ambiente les ofrece, corriendo el riesgo de que aprendan equivocadamente.</p>
						<p>Es prácticamente imposible modificar el contenido de los  medios, pero lo que si está en nuestras manos es cambiar y fortalecer los contextos más importantes en los que  se desarrollan : el familiar y escolar.</p>
						<p>Como respuesta a  esta necesidad,  apoyamos a los padres con la  aplicación,(App),  sobre educación sexual para (6 a 12 años) y prevención de abuso sexual de( 5 a 8 años). Está diseñada con un enfoque integral: se brinda información de acuerdo a las necesidades de cada edad, pero también es un medio que permite el desarrollo de una buena autoestima, disciplina, clarificar valores, equidad de género, etc. Elementos indispensables en la construcción de su personalidad.</p>
					</div>
				</div>
				<div class="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
					<img class="rounded-lg shadow-md" :src="require('@/assets/padres/child-parent.jpg')" alt="">
				</div>
			</div>
		</div>
	</div>

	<div class="py-6 xl:py-10 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
		<div class="max-w-max lg:max-w-7xl mx-auto">
			<div class="relative z-10 mb-8 md:mb-2 md:px-6">
				<div class="text-base max-w-prose lg:max-w-none">
				<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-vino sm:text-4xl">¿En qué consiste la aplicación?</p>
				</div>
			</div>
			<div class="relative">
				<div class="relative md:bg-white md:p-6">
				<div class="lg:grid lg:grid-cols-2 lg:gap-6">
					<div class="prose prose-indigo text-gray-500 lg:max-w-none space-y-4">
						<p>Consta de 5 módulos sobre educación sexual para edades de (6 a 12 años) y un módulo de  prevención de abuso sexual de (5 a 8 años.) que podrás adquirir individualmente , de acuerdo a la edad de tu hijo o hija.</p>
						<p>Cada módulo  esta compuesto por un libro que haces tuyo, al descargarlo en cualquier dispositivo y será la guía sobre como conducir la conversación con tu hija o hijo lo que te hará sentir con más seguridad para hablar con naturalidad e ir construyendo una visión positiva hacia la sexualidad.</p>
					</div>
					<div class="mt-6 prose prose-indigo text-gray-500 lg:mt-0 space-y-4">
						<p>Posterior al trabajo con el libro, te ofrecemos el acceso a una serie de juegos interactivos ( rompecabezas, memoria, adivinanzas, etc.), con los que se refuerza lo aprendido en forma relajada y divertida, y será más fácil que niñas y niños hagan suyos los conocimientos, sobre el cuerpo y funciones; cómo cuidarse a sí mismos.</p>
						<p>Al adquirir cada app tienes acceso a los juegos por tiempo ilimitado, para que tus hijas e hijos sigan aprendiendo.</p>
					</div>
				</div>
				</div>
			</div>
		</div>
  	</div>

	<div class="py-6 xl:py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
		<div class="max-w-max lg:max-w-7xl mx-auto">
			<div class="relative">
				<div class="relative md:bg-white md:p-6">
					<div class="lg:grid lg:grid-cols-2 lg:gap-6">
						<div class="prose prose-indigo text-gray-500 lg:max-w-none space-y-4">
							<div class="text-2xl font-semibold text-cyan">Ventajas</div>
							<ul> 
								<li>Es un excelente instrumento para educar por su fácil acceso y uso.</li>
								<li>Disponible en cualquier momento.</li>
								<li>Cumple con las  exigencias de niñas y niños sobre material de aprendizaje.</li>
								<li>El interés por el juego te ayuda a iniciar la conversación que en ocasiones no sabemos como empezar. </li>
								<li>Niñas y niños pasan mucho tiempo jugando en los celulares, podemos aprovechar este  tiempo para que aprendan sobre temas relevantes.</li>
								<li>Promueve educar oportunamente  a niñas y niños para que  reciban información correcta, confiable y de acuerdo a su edad y que lejos de acabar con su inocencia los libera de prejuicios y falsos conceptos que a la larga son muy difíciles de destruir.</li>
								<li>Te ayuda a cumplir con una  educación sexual  progresiva y continua. </li>
								<li>Posicionamiento, es decir, ser el primero en hablar del tema te convierte en su punto de  referencia.</li>
							</ul>
						</div>
						<div class="mt-6 prose prose-indigo text-gray-500 lg:mt-0 space-y-4">
							<div class="text-2xl font-semibold text-azul">Beneficios</div>
							<ul>
								<li>Construir una base sólida para que sean críticos  de lo que vean y oyen.</li>
								<li>Establecer las bases para futuras conversaciones.</li>
								<li>Abrir canales de comunicación y desarrollar la confianza.</li>
								<li>Promover fuentes confiables de información.</li>
								<li>Contrarrestar  información nociva.</li>
								<li>Promover la equidad de género.</li>
								<li>Alertarlos con respecto a problemas como abuso sexual.</li>
								<li>Fomentar una educación preventiva.</li>
								<li>Influir en la felicidad futura.</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>


<script>
// import { AcademicCapIcon } from '@heroicons/vue/outline'
import Menu from '@/components/core/Menu'

export default {
	components: {
		Menu
	}
}
</script>